import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
const Datetime = ({
    state,
    handleChangeDateArrival,
    handleChangeTimeArrival,
    handleChange,
    handleChangeAirlineArrival,
    handleChangeTimeDeparture,
    handleChangeDateDeparture,
    handleChangeAirlineDeparture}) => {

    const { destination, trip_type } = state;

  return (
    <>
        <div className="grid-x grid-padding-x">
            <div className="small-12 cell">
                <h4>
                    <i className="fas fa-plane-arrival"></i>
                    { destination === "AIRPORT SJD" && trip_type === "One Way"
                        ? "Departure Information:"
                        : "Arrival Information:"}{" "}
                </h4>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 medium-6 cell">
                        <label className="">
                            {" "}
                            <span>Select Date:</span>
                            <div className="input-group">
                                <span className="bg-icons input-group-time">
                                    <FontAwesomeIcon icon="calendar-alt" />
                                </span>
                                <DatePicker
                                    selected={state.startDateArrival}
                                    onChange={handleChangeDateArrival}
                                    className={"input-text"}
                                    dateFormat="MM/dd/yyyy"
                                    popperPlacement="top-start"
                                    minDate={moment()}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px",
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                        },
                                    }}
                                />
                            </div>
                        </label>
                    </div>
                    <div className="small-12 medium-6 cell">
                        <label className="">
                            {" "}
                            <span>Select Time:</span>
                            <div className="input-group">
                                <span className="bg-icons input-group-time">
                                    <FontAwesomeIcon icon="clock" />
                                </span>
                                <DatePicker
                                    name="arrival_time"
                                    value={state.arrival_time}
                                    selected={new Date()}
                                    onChange={handleChangeTimeArrival}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Arrival time picker"
                                    dateFormat="h:mm aa"
                                    autoComplete="off"
                                />
                            </div>
                        </label>
                    </div>
                    <div className="small-12 medium-6 cell">
                        <label className="ArrivalAirlineEnter">
                            {" "}
                            <span> flight's Number:</span>
                            <div className="input-group">
                                <span className="bg-icons input-group-time">
                                    <FontAwesomeIcon icon="plane-arrival" />
                                </span>
                                <input
                                    className="input-text"
                                    id="arrival_flight"
                                    value={state.arrival_flight}
                                    name="arrival_flight"
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Type Flight's Number"
                                    required
                                    autoComplete="off"
                                />
                            </div>
                        </label>
                    </div>
                    <div className="small-12 medium-6 cell">
                        <label className="ArrivalAirlineEnter">
                            {" "}
                            Airline's name:
                            <div className="input-group">
                                <span className="bg-icons input-group-time">
                                    <FontAwesomeIcon icon="plane-arrival" />
                                </span>
                                <Select
                                    className="select-search"
                                    onChange={handleChangeAirlineArrival}
                                    options={state.airlines}
                                />
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            {state.trip_type === "Round Trip" ? (
                <div className="small-12 cell departure-info">
                    {" "}
                    <hr />
                    <h4>
                        <i className="fas fa-plane-departure"></i>
                        Departure Information:
                    </h4>
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 medium-6 cell">
                            <label className="">
                                {" "}
                                <span>Select Time:</span>
                                <div className="input-group">
                                    <span className="bg-icons input-group-time">
                                        <FontAwesomeIcon icon="calendar-alt" />
                                    </span>
                                    <DatePicker
                                        selected={state.startDateDeparture}
                                        onChange={handleChangeDateDeparture}
                                        className={"input-text"}
                                        popperPlacement="top-start"
                                        dateFormat="MM/dd/yyyy"
                                        minDate={state.startDateArrival}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px",
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport",
                                            },
                                        }}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className="small-12 medium-6 cell">
                            <label className="DepartTime">
                                {" "}
                                <span> Select time:</span>
                                <div className="input-group">
                                    <span className="bg-icons input-group-time">
                                        <FontAwesomeIcon icon="clock" />{" "}
                                    </span>
                                    <DatePicker
                                        name="departure_time"
                                        value={state.departure_time}
                                        selected={new Date()}
                                        onChange={handleChangeTimeDeparture}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Departure time picker"
                                        dateFormat="h:mm aa"
                                        autoComplete="off"
                                    />
                                </div>
                            </label>
                        </div>
                        <div className="small-12 medium-6 cell" />
                        <div className="small-12 medium-6 cell">
                            <label className="DepartFlightEnter">
                                {" "}
                                <span>Pickup Departure at Hotel (must be 3 hours before):</span>
                                <div className="input-group">
                                    <span className="bg-icons input-group-time">
                                        <FontAwesomeIcon icon="plane-departure" />{" "}
                                    </span>
                                    <input
                                        className="input-text"
                                        id="departure_pickup_time_hotel"
                                        value={state.departure_pickup_time_hotel}
                                        name="departure_pickup_time_hotel"
                                        onChange={handleChange}
                                        type="text"
                                        autoComplete="off"
                                        disabled
                                    />
                                </div>
                            </label>
                        </div>
                        <div className="small-12 medium-6 cell">
                            <label className="DepartFlightEnter">
                                {" "}
                                <span> Flight Number:</span>
                                <div className="input-group">
                                    <span className="bg-icons input-group-time">
                                        <FontAwesomeIcon icon="plane-departure" />{" "}
                                    </span>
                                    <input
                                        className="input-text"
                                        id="departure_flight"
                                        value={state.departure_flight}
                                        name="departure_flight"
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Type Flight Number"
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                            </label>
                        </div>
                        <div className="small-12 medium-6 cell">
                            <label className="DepartAirlineEnter">
                                {" "}
                                <span>Airline's Name:</span>
                                <div className="input-group">
                                    <span className="bg-icons input-group-time">
                                        <FontAwesomeIcon icon="plane-departure" />
                                    </span>
                                    <Select
                                        className="select-search"
                                        onChange={
                                            handleChangeAirlineDeparture
                                        }
                                        options={state.airlines}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className="small-12 medium-6 cell">
                            <label className="DepartHotelExtra">
                                {" "}
                                <span> Hotel</span>
                                <div className="input-group">
                                    <span className="bg-icons input-group-time">
                                        <FontAwesomeIcon icon="map-marker-alt" />
                                    </span>
                                    <input
                                        className="input-text"
                                        id="hotel_extra"
                                        name="hotel_extra"
                                        value={state.hotel_extra}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Type Departure Hotel"
                                        autoComplete="off"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
        <hr />
    </>
  )
}

export default Datetime