import React, { useState, useEffect } from 'react'
import Header from "./../utils/header";
import Footer from "./../utils/footer";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import ExcelExport from '../reservations/excelexport'
// import DatePicker from "react-datepicker";
import { getAllServiceExtra } from "../../api_controller/service_extra.controller";
import TableServiceExtra from './table-service-extra/table-service-extra';
import { getDataById } from '../../services/reservation';

const ServiceExtra = () => {
    const [records, setRecords] = useState([]);

    useEffect(() => {
      getAllServiceExtra()
        .then(response => {
          // Transform each record to include SERVICE_EXTRA_NAME and SERVICE_EXTRA_PRICE
          const transformedRecords = response.data.results.map(record => ({
            ...record,
            key: record.ID || `record-${Math.random().toString(36).substring(2, 11)}`, // Add unique key for React
            SERVICE_EXTRA_NAME: record.SERVICIO_EXTRA?.name,
            SERVICE_EXTRA_PRICE: `$${record.SERVICIO_EXTRA?.price}`,
            SERVICE_EXTRA_TIME: `$${record.SERVICIO_EXTRA?.time}`
          }));
        //   console.log(transformedRecords);
          setRecords(transformedRecords);
        })
        .catch(error => {
          console.error("Error fetching service extra records:", error);
        });
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedReserve, setSelectedReserve] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleDetails = async (record) => {
        setIsLoading(true);
        try {
            const res = await getDataById(record.ID);
            let reserve = res.data.results[0];
            // console.log(res);
            setSelectedReserve(reserve);
            setIsLoading(false);
            setModalIsOpen(true);
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching details:", error);
        }
    };

const toggleModal = () => setModalIsOpen(!modalIsOpen);

return (
    <>
        <div id="booking-form" className="booking-form">
            <Header />
            <div id="breadcrumbs" className="full">
                <div className="grid-container">
                    <div className="contenedor-flex grid-x grid-padding-y"></div>
                </div>
            </div>
            <div className="grid-container">
                <div className="grid-x grid-padding-x grid-padding-y">
                    <div className="small-8 cell align-self-center pl-3">
                        <h1>EXTRA SERVICES</h1>
                    </div>
                    {/*
                    <div className="medium-4 cell d-flex justify-content-end align-items-end">
                        <ExcelExport/>
                    </div>
                    <div className="medium-5 cell">
                        <div className="radio-buttons">
                            <label>
                                Select Filter Data
                                <select name="filterOption">
                                    <option value="register">Register Date</option>
                                    <option value="arrival">Arrival Date</option>
                                    <option value="departure">Departure Date</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="medium-2 cell">
                        <label>
                            Initial Date
                            <DatePicker></DatePicker>
                        </label>
                    </div>
                    <div className="medium-2 cell">
                        <label>
                            Date End
                            <DatePicker></DatePicker>
                        </label>
                    </div>
                    <div className="medium-1 cell align-self-bottom d-flex justify-content-end">
                        <button type="submit" className="button btn-primary">
                            SEARCH
                        </button>
                    </div>
                    */}
                </div>
            </div>
            <div className="grid-container">
                <div className="grid-x grid-padding-x grid-padding-y"></div>
                <div className="grid-x grid-padding-x grid-padding-y b-white">
                    <div className="small-12 cell">
                        <TableServiceExtra records={records} handleDetails={handleDetails} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        <Modal
            isOpen={modalIsOpen}
            toggle={toggleModal}
            size="lg"
            scrollable={true}
            className="modal-show-info"
        >
            <ModalHeader toggle={toggleModal}>
                RESERVATION ID: {selectedReserve?.ID}
            </ModalHeader>
            <ModalBody>
                <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 medium-12 cell text-left">
                            <div className="grid-x">
                                <div className="small-12 medium-6 cell info-fix">
                                    <h4>
                                        Promotion: <span className="promotion_text">10%</span> off
                                    </h4>
                                    {selectedReserve?.rcontrol && (
                                        <p>
                                            Regular Rate:{" "}
                                            <strong className="line-through">
                                                ${selectedReserve.rcontrol.TOTAL} USD
                                            </strong>
                                        </p>
                                    )}
                                    {selectedReserve?.SERVICIO_EXTRA ? (
                                        <>
                                            <p>
                                                Extra Service:{" "}
                                                <strong>
                                                    ${selectedReserve.SERVICIO_EXTRA.price} USD
                                                </strong>
                                            </p>
                                            <p>
                                                Promo Rate: <strong>${selectedReserve.COSTO} USD</strong>
                                            </p>
                                            <h4
                                                autoFocus
                                                className="font-weight-bold card-title"
                                            >
                                                TOTAL: $
                                                {selectedReserve.COSTO +
                                                    selectedReserve.SERVICIO_EXTRA.price}{" "}
                                                USD
                                            </h4>
                                        </>
                                    ) : (
                                        <h4 autoFocus className="font-weight-bold card-title">
                                            TOTAL: ${selectedReserve?.COSTO} {selectedReserve?.MONEDA}
                                        </h4>
                                    )}
                                    <hr />
                                    <p>
                                        <b>Trip type:</b>{" "}
                                        <span className="pax_num">
                                            {selectedReserve?.trip_type}
                                        </span>
                                    </p>
                                    <p>
                                        <b>Passengers:</b>{" "}
                                        <span className="pax_num">
                                            {selectedReserve?.NPASAJEROS}
                                        </span>
                                    </p>
                                    <p>
                                        <b>Transport Selected:</b>{" "}
                                        <span>
                                            {selectedReserve?.TRANSPORTE === "Sedan"
                                                ? "Small SUV"
                                                : selectedReserve?.TRANSPORTE}
                                        </span>
                                    </p>
                                    <p>
                                        <b>Pickup Location:</b>{" "}
                                        <span>{selectedReserve?.HOTEL}</span>
                                    </p>
                                    <p>
                                        <b>Destination:</b>{" "}
                                        <span>{selectedReserve?.HOTEL_DESTINO}</span>
                                    </p>
                                    <p>
                                        <b>Pickup Departure Location:</b>{" "}
                                        <span>{selectedReserve?.HOTEL_EXTRA}</span>
                                    </p>
                                    <hr />
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-6 cell info-fix">
                                    <h5>Contact / Traveler Information:</h5>
                                    <div id="fullnameTraveler">
                                        <p>
                                            <b>Full name: </b>{" "}
                                            <span className="fullname">
                                                {`${selectedReserve?.NOMBRE} ${selectedReserve?.APELLIDO}`}
                                            </span>
                                        </p>
                                    </div>
                                    <div id="phoneTraveler">
                                        <p>
                                            <b>Phone: </b>
                                            <span className="phone">
                                                {selectedReserve?.TELEFONO}
                                            </span>
                                        </p>
                                    </div>
                                    <div id="emailTraveler">
                                        <p>
                                            <b>E-mail: </b>
                                            <span className="email">
                                                {selectedReserve?.CORREO}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="small-6 cell">
                                    <h5>Comments:</h5>
                                    <p>{selectedReserve?.COMENTARIOS}</p>
                                </div>
                                <div className="small-12 medium-6 cell info-fix">
                                    <hr />
                                    <h5>
                                        {selectedReserve?.HOTEL_DESTINO === "AIRPORT SJD" &&
                                        selectedReserve?.trip_type === "One Way"
                                            ? "Departure Information:"
                                            : "Arrival Information:"}
                                    </h5>
                                    <div className="grid-y">
                                        <p>
                                            <b>Date / Time:</b>{" "}
                                            <span>
                                                {selectedReserve?.FECHA_LLEGADA}{" "}
                                                {selectedReserve?.HORA_IN}
                                            </span>
                                        </p>
                                        <p>
                                            <b>Flight Number: </b>
                                            <span>{selectedReserve?.VUELO_IN}</span>
                                        </p>
                                        <p>
                                            <b>Airline Name: </b>
                                            <span>{selectedReserve?.AEROLINEA_IN}</span>
                                        </p>
                                    </div>
                                </div>
                                {selectedReserve?.FECHA_SALIDA !== "N/A" && (
                                    <div className="small-12 medium-6 cell info-fix">
                                        <hr />
                                        <h5>Departure Information:</h5>
                                        <div className="grid-y">
                                            <p>
                                                <b>Date / Time: </b>
                                                <span>
                                                    {selectedReserve?.FECHA_SALIDA}{" "}
                                                    {selectedReserve?.HORA_OUT}
                                                </span>
                                            </p>
                                            <p>
                                                <b>Hotel Pickup Time: </b>
                                                <span>
                                                    {selectedReserve?.PICKUP_AT_HOTEL}
                                                </span>
                                            </p>
                                            <p>
                                                <b>Flight Number: </b>
                                                <span>{selectedReserve?.VUELO_OUT}</span>
                                            </p>
                                            <p>
                                                <b>Airline Name: </b>
                                                <span>{selectedReserve?.AEROLINEA_OUT}</span>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {selectedReserve?.SERVICIO_EXTRA && (
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <hr />
                                        <h5>Extra Service</h5>
                                        <div>
                                            <p>
                                                <b>Service Name: </b>{" "}
                                                <span className="fullname">
                                                    {selectedReserve?.SERVICIO_EXTRA.name}
                                                </span>
                                            </p>
                                            <p>
                                                <b>Service Time Limit: </b>
                                                <span className="phone">
                                                    {selectedReserve?.SERVICIO_EXTRA.time} minutes
                                                </span>
                                            </p>
                                            <p>
                                                <b>Service Price: </b>
                                                <span className="phone">
                                                    ${selectedReserve?.SERVICIO_EXTRA.price}USD
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={toggleModal}
                >
                    <i className="fa fa-check-circle"></i> &nbsp; ACEPT
                </button>
            </ModalFooter>
        </Modal>
    </>
);
}

export default ServiceExtra