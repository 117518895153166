import React, { Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import "font-awesome/css/font-awesome.min.css";

const TableServiceExtra = ({ handleDetails, records }) => {
  const columns = [    
    {
        key: "ID",
        text: "ID Reserve",
        align: "center",
        sortable: true,
    },
    {
        key: "CORREO",
        text: "Email",
        align: "center",
        sortable: true,
    },
    {
        key: "NOMBRE",
        text: "Name",
        align: "center",
        sortable: true,
    },
    {
        key: "TRANSPORTE",
        text: "Fleet Transport",
        align: "center",
        sortable: true,
    },
    {
        key: "SERVICE_EXTRA_NAME",
        text: "Extra Service Name",
        align: "center",
        sortable: true,
    },
    {
        key: "SERVICE_EXTRA_TIME",
        text: "Extra Service Time",
        align: "center",
        sortable: true,
    },
    {
        key: "SERVICE_EXTRA_PRICE",
        text: "Extra Service Price",
        align: "center",
        sortable: true,
    },
    {
        key: "action",
        text: "Action",
        width: 125,
        align: "center",
        className: "action",
        sortable: false,
        cell: (record) => {
            return (
                <Fragment key={record.ID}>
                    <button className="btn btn-dark btn-sm" style={{ marginRight: "4px" }} onClick={() => handleDetails(record)}>
                        <i className="fa fa-info-circle"></i>
                    </button>
                </Fragment>
            );
        },
    },
  ];

  const config = {
    page_size: 50,
    length_menu: [50, 100, 200],
    button: { excel: false },
    sort: { column: "ID", order: "desc" },//sort: {column:"", order:"desc"}
  };

  return (
    <ReactDatatable
      className="table stack b-white hover table-bordered"
      key={"table22"}
      config={config}
      records={records}
      columns={columns}
    />
  );
};

export default TableServiceExtra;
