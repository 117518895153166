import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from 'reactstrap';
import styles from './get-stpp-super-market.module.scss';
import PropTypes from 'prop-types';

export const GetStopSuperMarket = ({
  reserve,
  extraService,
  setTrueIsServiceActive,
  setFalseIsServiceActive,
  args
}) => {
  // Estado para manejar tooltips de cada servicio
  const [tooltipOpen, setTooltipOpen] = useState({});
  const toggleTooltip = (id) =>
    setTooltipOpen(prev => ({ ...prev, [id]: !prev[id] }));

  const [disabledButton, setDisabledButton] = useState(true);
  // Estado para identificar el servicio actualmente seleccionado (radio button)
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  useEffect(() => {
    setDisabledButton(!reserve.rate || reserve.destination === 'AIRPORT SJD');
  }, [reserve.rate, reserve.destination]);

  // Actualizar handleButtonClick para comportarse como radio button
  const handleButtonClick = (id) => {
    const clickedService = extraService?.find(service => service.id === id);
    // console.log(clickedService);
    if (selectedServiceId === id) {
      setFalseIsServiceActive(reserve.rate, clickedService.time, clickedService);
      setSelectedServiceId(null);
    } else {
      if (selectedServiceId) {
        const previousService = extraService?.find(service => service.id === selectedServiceId);
        if(previousService){
          setFalseIsServiceActive(reserve.rate, previousService.time, previousService);
        }
      }
      setTrueIsServiceActive(reserve.rate, clickedService.time, clickedService);
      setSelectedServiceId(id);
    }
  };

  return (
    <div className={styles.container}>
      {extraService.map(service => (<React.Fragment key={service.id}>
        <div key={service.id} className={styles.service_container}>
          <div className={styles.icon_container} />
          <div>
            <h6 style={{fontWeight: '600'}}>{service.name}</h6>
            <p style={{fontWeight: 'bold', color: '#fa5454'}}>Time to Stop: {service.time}</p>
          </div>
          <h5 style={{ color: '#000000' }}>${service.price} USD</h5>
          <Button
            id={`disableButton-${service.id}`}
            color="secondary"
            onClick={() => handleButtonClick(service.id)}
            disabled={disabledButton}
          >
            {selectedServiceId === service.id ? 'Selected / Remove' : 'Add'}
          </Button>
          {disabledButton && (
            <Tooltip
              {...args}
              isOpen={tooltipOpen[service.id] || false}
              target={`disableButton-${service.id}`}
              toggle={() => toggleTooltip(service.id)}
            >
              Please select Pickup and Destination first. The Destination must be a hotel.
            </Tooltip>
          )}
        </div>
        <hr />
        </React.Fragment>
      ))}
    </div>
  );
};

GetStopSuperMarket.propTypes = {
  reserve: PropTypes.shape({
    rate: PropTypes.any,
    destination: PropTypes.string,
  }).isRequired,
  isServiceActive: PropTypes.bool,
  extraService: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  setTrueIsServiceActive: PropTypes.func.isRequired,
  setFalseIsServiceActive: PropTypes.func.isRequired,
  args: PropTypes.object,
};

GetStopSuperMarket.args = {
  autohide: true,
  flip: true,
};

GetStopSuperMarket.argTypes = {
  placement: {
    control: { type: 'select' },
    options: ['top', 'left', 'right', 'bottom'],
  },
};
